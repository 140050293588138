<template>
  <el-scrollbar style="height: 100%">
  <div class="course-box">
    <div class="operate-box">
      年份
      <el-date-picker
          v-model="year"
          type="year"
          value-format="timestamp"
          placeholder="选择年">
      </el-date-picker>
    </div>
    <div class="data-box">
      <div id="dataEcharts" style="height: 400px"></div>
    </div>
  </div>
  </el-scrollbar>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      year: moment().unix() * 1000,
      dataEcharts: null
    }
  },
  mounted() {
    this.dataEcharts = this.$echarts.init(document.getElementById('dataEcharts'));

    let resizeTimer = null;
    window.onresize = () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        this.dataEcharts.resize()
      }, 100)
    }
    this.getDataEcharts()
  },
  methods: {
    getDataEcharts() {
      this.dataEcharts.setOption({
        legend: {
          data: ['作业数', '批改数']
        },
        xAxis: {
          type: 'category',
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        },
        yAxis: [
          {
            type: 'value',
            name: '作业数',
          },
          {
            type: 'value',
            name: '批改数',
          },
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985',
            }
          },
        },
        grid: {
          top: 40,
          left: 50,
          right: 50,
          bottom: 25,
        },
        series: [
            {
              name: '作业数',
              type: 'bar',
              data: [56, 80, 21, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              color: ['#71A3FF'],
              barWidth: 24,
              itemStyle: {
                normal: {
                  barBorderRadius: [12, 12, 0, 0],
                }
              }
            },
            {
              name: '批改数',
              type: 'bar',
              data: [1680, 2400, 630, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              color: ['#9F8BFF'],
              barWidth: 24,
              yAxisIndex: 1, //在单个图表实例中存在多个y轴的时候有用
              itemStyle: {
                normal: {
                  barBorderRadius: [12, 12, 0, 0],
                }
              }
            },
        ]
      });
    }
  }
}
</script>

<style scoped lang="scss">
:deep(.el-scrollbar__wrap) {
  overflow-x: hidden;
}
.course-box {
  padding: 30px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .operate-box {
    margin-bottom: 20px;
  }
}
.data-box {
  border: 1px solid #DADCE7;
  padding: 40px;
}
</style>